import React from "react";
import ReactDOMClient from 'react-dom/client';
import singleSpaReact from "single-spa-react";
import rootComponent from "./app";

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent,
  renderType: "createRoot",
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
      return <div>An unhandled frontend error occurred</div>;
  },
});
