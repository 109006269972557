import React from "react";
import { useSuggestions } from 'ui'

function App() {
    const translationState = (window as any).app.preloadState.translation;
    const preloadState = (window as any).app.preloadState.headerSearchBox;
    const { results, onFocus, onBlur, onInput, onKeyUp, value } = useSuggestions();

    return (
        <>
            <input id="HeaderSearchBox" onKeyUp={onKeyUp} spellCheck="false" onInput={onInput} onFocus={onFocus} onBlur={onBlur} type="text" placeholder={translationState["headerSearchBox.placeholder"]} />
            {results.length > 0 && <div className="headerSearchBoxResults">
                {results.map(result => (
                    <a key={result.itemNumber} href={result.url}>
                        <span className="font-weight-bold">{result.displayName}</span> -  <span className={"d-inline-block firstLetterCapital"}>{translationState["sparePartItem.articleNo"]}: {result.itemNumber}</span>
                    </a>
                ))}
                <a href={`${preloadState.searchResultPage}?query=${value}`}>
                    <span className="font-weight-bold">{translationState["sparePartItem.showAllSpareParts"]}</span>
                </a>
            </div>}
        </>
  )
}

export default App

